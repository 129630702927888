import React, {
  Component,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../../Sidebar/header";
import Sidebar from "../../../Sidebar/sidebarAdmin";
import Addpayroll from "./Addpayroll";
import RevisePayroll from "../OverviewPayroll/RevisePayroll";
import ViewPayslip from "../OverviewPayroll/ViewPayroll";
import Back from "../../../../assets/img/profiles/arrow-left.svg";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Rupee from "../../../../assets/img/profiles/Rupee-Symbol-Black.svg";
import SapioLogo from "../../../../assets/img/profiles/sapio-logo.svg";
import closeIcon from "../../../../assets/img/profiles/close-circle-fill.svg";

// import Button from "@mui/material/Button";
import PayrollHistory from "./PayrollHistory";
import axios from "axios";
import { CSVLink } from "react-csv";
import rupee from "../../../../assets/img/profiles/Rupee-Symbol-White-PNG.png";
import { width } from "@mui/system";
import { css, jsx } from "@emotion/react";
import Swal from "sweetalert2";
import Button from "@atlaskit/button/standard-button";
import CrossIcon from "@atlaskit/icon/glyph/cross";
import { N500 } from "@atlaskit/theme/colors";
import { token } from "@atlaskit/tokens";
import { useReactToPrint } from "react-to-print";
import Applogo from "../../../../assets/img/image23.png";
import Modal, {
  ModalBody,
  ModalFooter,
  ModalTransition,
  useModal,
} from "@atlaskit/modal-dialog";
import { tableCellClasses } from "@mui/material/TableCell";
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  // Button,
  Toolbar,
  Grid,
  IconButton,
} from "@mui/material";
import styled from "@emotion/styled";
import { borderRadius } from "@mui/system";
import { ArrowBack } from "@mui/icons-material";
const STableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  // hide last border
  "&:nth-of-type(even)": {
    backgroundColor: "#e6e6fa",
  },
}));

const STableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  fontSize: "13px",
  color: "#808080",
});

const SNCTableCell = styled(TableCell)({
  borderColor: "#FFFFFF",
  fontSize: "13px",
  color: "#808080",
});

const SCTableCell = styled(TableCell)({
  borderBottomColor: "#000000",
  // background: "#a2d8fb",
  // color:"#808080"
});
const LATableCell = styled(TableCell)({
  textAlign: "right",
});

const SButton = styled(Button)({
  background: "#2DB8C5",
  borderRadius: "4px",
  textTransform: "none",
  boxShadow: "none",
  padding: "8px",
  ":hover": {
    background: "#2DB8C5",
    boxShadow: "none",
  },
});
const headerStyles = css({
  display: "flex",
  padding: token("space.300", "24px"),
  alignItems: "center",
  justifyContent: "space-between",
});

const headingStyles = css({ fontSize: 20, fontWeight: 500 });

const CustomHeader = () => {
  const { onClose, titleId } = useModal();
  return (
    <div css={headerStyles}>
      <h1 css={headingStyles} id={titleId}>
        Custom modal header
      </h1>
      <Button appearance="link" onClick={onClose}>
        <CrossIcon
          label="Close Modal"
          primaryColor={token("color.text.subtle", N500)}
        />
      </Button>
    </div>
  );
};
const Runpayroll = () => {
  const [menu, setMenu] = useState(false);
  const [empPay, setEmpPay] = useState([]);
  const [showDown, setShowDown] = useState(false);
  const [showPayslip, setShowPayslip] = useState(false);
  const [showp, setShowp] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSlip, setShowSlip] = useState([]);
  const [totalEr, setTotalEr] = useState(0);
  const [totalDe, setTotalDe] = useState(0);
  const [totalRe, setTotalRe] = useState(0);
  const [show, setShow] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [dyCol, setDyCol] = useState([]);
  const [show1, setShow1] = useState([]);
  const [empi, setEmpi] = useState("");
  const[gDate,setGDate]=useState(``)
  const [month, setMonth] = useState(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 > 9
        ? new Date().getMonth() + 1
        : `0${new Date().getMonth() + 1}`
    }`
  );
  const [monthValue, setMonthValue] = useState("");

  const rolePermissions = JSON.parse(sessionStorage.getItem("permissions"));
  const mo = {
    "01": "JANUARY",
    "02": "FEBRUARY",
    "03": "MARCH",
    "04": "APRIL",
    "05": "MAY",
    "06": "JUNE",
    "07": "JULY",
    "08": "AUGUST",
    "09": "SEPTEMBER",
    10: "OCTOBER",
    11: "NOVEMBER",
    12: "DECEMBER",
  };
  function convertToHours(timeString) {
    // Split the time string into parts
    const [days, minutes, seconds] = timeString.split(':').map(Number);

    // Normalize minutes into hours and minutes
    const normalizedHoursFromMinutes = Math.floor(minutes / 60); // Convert excess minutes to hours
    const remainingMinutes = minutes % 60; // Get remaining minutes

    // Convert days to hours
    const hoursFromDays = days * 24;

    // Calculate total hours
    const totalHours = hoursFromDays + normalizedHoursFromMinutes;

    // return {
    //     totalHours,
    //     remainingMinutes,
    // };
    return totalHours
}

  const rrwlcolumns = [
    { name: "Employee ID", key: "empid" },
    { name: "Employee Name", key: "name" },
    { name: "Employee Code", key: "empcode" },
    { name: "Location", key: "office" },
    { name: "Zing Code", key: "extra_code" },
    { name: "Designation", key: "designation" },
    { name: "Grade", key: "level" },
    { name: "Department", key: "department" },
    { name: "YEAR", key: "year" },
    { name: "MONTH", key: "month" },
    { name: "Working Days", key: "days" },
 
    { name: "Present Days", key: "p_days" },
    // { name: "PH", key: "" },
    {name:`Week Off`,key:`wo_works`},
    {name:`Holiday`,key:`holiday`},
    { name: "PL", key: "pl" },
    { name: "CL", key: "cl" },
    { name: "SL", key: "sl" },
    { name: "ML", key: "ma_l" },
    // { name: "LWP", key: "lwp" },
  
    // {name:`Half Day`,key:`half_day`},
    // {name:`Short Leave`,key:`full_short_leave`},
    
    { name: "Worked Days", key: "paid_days" },
    { name: "Absent (LWP)", key: "ab_days" },
    { name: "Actual Basic", key: "Basic" },
    // { name: "Actual HRA", key: "House Rent Allowance" },
    // { name: "Actual Monthly Gross", key: "gross_salary_monthly" },
    // { name: "Actual Net Pay", key: "" },
    { name: "Basic", key: "Basic_paid" },
    // { name: "Arrear Basic", key: "" },
    { name: "HRA", key: "HRA_paid" },
    // { name: "Arrear HRA", key: "" },
    { name: "Medical allowance", key: "Medical Allowance " },
    // { name: "Arrear Med.", key: "" },
    { name: "Education allowance", key: "Education Allowance" },
    // { name: "Arrear Edu.", key: "" },
    { name: "Conveyance allowance", key: "Conveyance Allowance" },
    // { name: "Arrear Conv.", key: "" },
    { name: "Cost of Vehicle", key: "Cost of Vehicle" },
    // { name: "Arrear C.V", key: "" },
    { name: "Special Allowances ", key: "Special Allowance" },
    // { name: "Arrear Other", key: "" },
    { name: "LTA", key: "LTA" },
    { name: "NPS", key: "nps" },
    { name: "EARN OT", key: "OT" },
    { name: "Total Gross Without OT", key: "gross_salary_without_overtime_before_deduction" },
    // { name: "Arrears NPS", key: "" },
    // { name: "Total Earning", key: "" },
    { name: "PF", key: "pf_deduction" },
    // { name: "Arrear PF", key: "" },
    { name: "Electric", key: "electric" },
    { name: "Mess", key: "mess" },
    { name: "Maintenance Charge", key: "maintenance_charges" },
    { name: "Loan", key: "loan" },
    { name: "Advance", key: "advance" },
    { name: "TDS", key: "tds" },
  
    { name: "Professional Tax", key: "pt" },
    { name: "Rent", key: "rent" },
    { name: "L I C", key: "lic" },
    { name: "Other deduction", key: "other_deduction" },
    { name: "NPS", key: "nps" },
    { name: "ESI", key: "esi" },

    { name: "Total deduction", key: "total_deduction" },
    // { name: "Net Pay", key: "net_salary" },
    { name: "NET PAY", key: "gross_salary_without_overtime_after_deduction" },
    // { name: "OT", key: "" },

    // { name: "Paid Holiday OT hours", key: "ho_hrs" },
    { name: "Paid Holiday OT Amount", key: "ho_OT_pay" },
    // { name: "Week Off OT hours", key: `wo_hrs` }, 
    { name: "Week Off OT amount", key: "wo_OT_pay" },
    // { name: "Regular OT hours", key: "regular_ot"  },
    { name: "Regular OT amount", key: "above_regular_ot_pay" },
    { name: "Incentive/Reward", key: "" },
    { name: "Monthly Total PAY", key: "gross_salary_with_overtime_after_deduction" },
    // { name: "Payable Extra & OT", key: "net_overtime_pay" },
    // { name: "CONV.REIMBURSEMENT", key: "" },

    // { name: "Total Gross With OT", key: "gross_salary_with_overtime_before_deduction" },

    
  ];
  
  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);
  const toggleMobileMenu = () => {
    setMenu(!menu);
  };
  const inputRef = useRef(null);
  const printPage = useReactToPrint({
    content: () => inputRef.current,
    documentTitle: "downloadpayslip",
  });
  const execute = empPay.filter((ele) => show1?.includes(ele.key));

  const pay = empPay.filter((ele) => selectedRowKeys.includes(ele.key));

  const sum = pay.reduce((accumulator, object) => {
    return accumulator + object.net_pay;
  }, 0);

  const handlePayslip = (text) => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payslip/`, {
        params: {
          empl_id: text,
          month: month.split("-")[1],
          year: month.split("-")[0],
          toggle: "s",
        },
      })
      .then((res) => {
        console.log(res.data, "Hello");
        setShowSlip(res.data);
        //setYearlyPayslip(res.data);
        const sum = res.data.earning?.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalEr(sum);
        const sum1 = res.data.deduction?.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalDe(sum1);
        const sum2 = res.data.reimbursment?.reduce((accumulator, object) => {
          return accumulator + object.total;
        }, 0);
        setTotalRe(sum2);
        setShowp(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleExecute = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/execute_payroll/`, {
        list_empid: selectedRowKeys,
        month: month.split("-")[1],
        year: month.split("-")[0],
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "Payroll For Selected Employees Executed Successfully",
          // timer: 3000,
        });
        setShowDown(true);
        closeModal();
        handleGetPayroll1();
      })
      .catch((err) => {
        alert("Payroll Not Executed");
        setShowDown(true);
        closeModal();
      });
  };

  const handleHoldPayroll = () => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        // month: month.split("-")[1],
        // year: month.split("-")[0],
        empl_id: selectedRowKeys,
        comp_id: sessionStorage.getItem("coid"),
        toggle: "stopped",
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "Payroll hold Successfully Selected Employees",
          // timer: 3000,
        });
        setSelectedRowKeys([]);
        handleGetPayroll1();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetPayroll = () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
        params: {
          empl_id: sessionStorage.getItem("emp_id"),
          toggle: "t2",
          comp_id: sessionStorage.getItem("coid"),
          month: month.split("-")[1],
          year: month.split("-")[0],
        },
      })
      .then((res) => {
        console.log(res.data);
        // setEmpPayrollDetails(res.data);
        // searchData(res.data);
        setEmpPay(
          res.data.map((ele) => ({
            enabled: false,
            key: ele.emp_id,
            emp_id: ele.emp_id,
            emp_name: ele.emp_name,
            emp_dept: ele.emp_dept,
            emp_desg: ele.emp_desg,
            emp_type: ele.emp_type,
            emp_email: ele.emp_email,
            day_no: ele.day_no,
            ctc: ele.ctc,
            status: ele.status,
            basic: ele.basic,
            deduction: ele.deduction,
            taxes: ele.taxes,
            reimbursement: ele.reimbursement,
          }))
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePFDeduction = (e) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/pf_deduction_in_payroll/`, {
        month: monthValue.split("-")[1],
        year: monthValue.split("-")[0],
        comp_id: sessionStorage.getItem("coid"),
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          // title: "Oops...",
          text: "PF Deduction calculated Successfully",
          // timer: 3000,
        });
        handleGetPayroll1();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleGetPayroll1 = () => {
    setLoading(true);
    if(sessionStorage.getItem(`coid`)===`Ram 067802`){
      Swal.fire({
        // title: "Attendance Report Downloading !",
        html: "Payroll data generation is in progress. This may take a few moments. Please stay on this page or check back later. Thank you for your patience!",
        // timer: 2000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          // const timer = Swal.getPopup().querySelector("b");
          // timerInterval = setInterval(() => {
          //   timer.textContent = `${Swal.getTimerLeft()}`;
          // }, 100);
        },
      });
    }
   
    if (
      !["OASI370734", "OASI019787", "S3 F251778", "OM N160326" , `Ram 067802`].includes(
        sessionStorage.getItem("coid")
      )
    ) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
            toggle: "t4",
            complaince: "F",
            comp_id: sessionStorage.getItem("coid"),
            month:parseInt(month.split("-")[1]) ,
            year: parseInt(month.split("-")[0]) ,
          },
        })
        .then((res) => {
          setDyCol(res.data.columns);
          setShow1(res.data.payroll_executed);
          // setEmpPayrollDetails(res.data);
          // searchData(res.data);
          setEmpPay(
            res.data.data.map((ele) => ({
              enabled: false,
              key: ele.employee_id,
              ...ele,
            }))
          );
          setLoading(false);
            Swal.fire({
                    icon: "success",
                    text: " Payroll data has been generated successfully!",
                  });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if(sessionStorage.getItem(`coid`)===`Ram 067802`){
      const formData = new FormData();

formData.append("comp_id", sessionStorage.getItem("coid"));
formData.append("month", month.split("-")[1]);
formData.append("year", month.split("-")[0]);

      axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/payroll/v2/`,
       
      formData,
      )
      .then((res) => {
        // setDyCol(res.data.columns);
        setShow1(res.data.payroll_executed);
        // setEmpPayrollDetails(res.data);
        // searchData(res.data);
        setGDate(res.data.generation_date)
        setEmpPay(
          res.data.data.map((ele) => ({
            enabled: false,
            office_name:ele.office,
            key: ele.empid,
            employee_name:ele.name,
            ...ele,
          }))
        );
        setLoading(false);
        Swal.fire({
          icon: "success",
          text: "Payroll data has been generated successfully!",
        });
})
            .catch((err) => {
        console.log(err);
      });

    }else {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/oasis_payroll/`, {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
            toggle: "t4",
            complaince: "F",
            comp_id: sessionStorage.getItem("coid"),
            month: month.split("-")[1],
            year: month.split("-")[0],
          },
        })
        .then((res) => {
          setDyCol(res.data.columns);
          setShow1(res.data.payroll_executed);
          // setEmpPayrollDetails(res.data);
          // searchData(res.data);
          setEmpPay(
            res.data.data.map((ele) => ({
              enabled: false,
              key: ele.employee_id,
              ...ele,
            }))
          );
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: "Payroll data has been generated successfully!",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
 console.log(empPay) 

  const handleGetPayrollS3 = () => {
    setLoading(true);
    if (
      !["OASI370734", "OASI019787", "S3 F251778", "OM N160326" , `Ram 067802`].includes(
        sessionStorage.getItem("coid")
      )
    ) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/payroll/`, {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
            toggle: "t4",
            complaince: "F",
            comp_id: sessionStorage.getItem("coid"),
            month: month.split("-")[1],
            year: month.split("-")[0],
          },
        })
        .then((res) => {
          setDyCol(res.data.columns);
          setShow1(res.data.payroll_executed);
          // setEmpPayrollDetails(res.data);
          // searchData(res.data);
          setEmpPay(
            res.data.data.map((ele) => ({
              enabled: false,
              key: ele.employee_id,
              ...ele,
            }))
          );
          setLoading(false);
            Swal.fire({
                    icon: "success",
                    text: " Payroll data has been generated successfully!",
                  });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if(sessionStorage.getItem(`coid`)===`Ram 067802`){
      const formData = new FormData();

formData.append("comp_id", sessionStorage.getItem("coid"));
formData.append("month", month.split("-")[1]);
formData.append("year", month.split("-")[0]);

      axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/payroll/data/`,
       
      formData,
      )
      .then((res) => {
        // setDyCol(res.data.columns);
        setShow1(res.data.payroll_executed);
        // setEmpPayrollDetails(res.data);
        // searchData(res.data);
        setGDate(res.data.generation_date)
        setEmpPay(
          res.data.data.map((ele) => ({
            enabled: false,
            office_name:ele.office,
            key: ele.empid,
            employee_name:ele.name,
            department:ele.dep_id,
            ...ele,
          }))
        );
        setLoading(false);
        // Swal.fire({
        //   icon: "success",
        //   // title: "Oops...",
        //   text: "The data you are viewing might be outdated. Please regenerate the payroll to view the updated information.",
        //   // timer: 3000,
        // });
         
})
            .catch((err) => {
        console.log(err);
        if(err.response.data.data.length===0){
          handleGetPayroll1()
        }

      });

    }else {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/oasis_payroll/`, {
          params: {
            empl_id: sessionStorage.getItem("emp_id"),
            toggle: "t4",
            complaince: "F",
            comp_id: sessionStorage.getItem("coid"),
            month: month.split("-")[1],
            year: month.split("-")[0],
          },
        })
        .then((res) => {
          setDyCol(res.data.columns);
          setShow1(res.data.payroll_executed);
          // setEmpPayrollDetails(res.data);
          // searchData(res.data);
          setEmpPay(
            res.data.data.map((ele) => ({
              enabled: false,
              key: ele.employee_id,
              ...ele,
            }))
          );
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: "Payroll data has been generated successfully!",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if(sessionStorage.getItem(`coid`)===`Ram 067802`){
      handleGetPayrollS3()
    }else{
      handleGetPayroll1();
    }

    
  }, [month]);

  const th = ["", "thousand", "million", "billion", "trillion"];
  const dg = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  const tn = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  const tw = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  function getInWords(s) {
    s = s?.toString();
    s = s?.replace(/[\, ]/g, "");
    if (s != parseFloat(s)) return " ";
    let x = s?.indexOf(".");
    if (x == -1) x = s?.length;
    if (x > 15) return "too big";
    let n = s?.split("");
    let str = "";
    let sk = 0;
    for (let i = 0; i < x; i++) {
      if ((x - i) % 3 == 2) {
        if (n[i] == "1") {
          str += tn[Number(n[i + 1])] + " ";
          i++;
          sk = 1;
        } else if (n[i] != 0) {
          str += tw[n[i] - 2] + " ";
          sk = 1;
        }
      } else if (n[i] != 0) {
        // 0235
        str += dg[n[i]] + " ";
        if ((x - i) % 3 == 0) str += "hundred ";
        sk = 1;
      }
      if ((x - i) % 3 == 1) {
        if (sk) str += th[(x - i - 1) / 3] + " ";
        sk = 0;
      }
    }

    if (x != s.length) {
      let y = s.length;
      str += "point ";
      for (let i = x + 1; i < y; i++) str += dg[n[i]] + " ";
    }
    return str?.replace(/\s+/g, " ");
  }

  // useEffect(() => {
  //   handleGetPayroll();
  // }, [month]);

  // console.log(
  //   dyCol.map((ele) => ({
  //     label: ele.name.replace(".", ""),
  //     key: ele.key,
  //   }))
  // );
  // console.log(month.split("-")[1]);
  const navigate = useNavigate();
  return (
    <div className={`main-wrapper ${menu ? "slide-nav" : ""}`}>
      <Header onMenuClick={(value) => toggleMobileMenu()} />
      <Sidebar />
      <div
        className="page-wrapper"
        // style={{ paddingTop: "60px", display: "flex", paddingLeft: "200px" }}
      >
        {/* Page Content */}
        <div
          className="col-md-12 row"
          // style={{ display: "flex" }}
        >
          <div className="col-md-9">
            {/* Page Header */}
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col">
                  <h3 className="page-title">
                    {(show === 2 || show === 3) && (
                      <>
                        <span>
                          <button
                            className="btn btn-success btn-block "
                            style={{
                              padding: "3px 3px",
                              fontSize: "15px",
                              background: " #3298DB",
                              border: "none",
                              color: "white",
                              borderRadius: "20px",
                            }}
                            classname="edit-icon"
                            onClick={() => setShow(1)}
                          >
                            <img src={Back} />
                          </button>
                        </span>
                        &nbsp;{" "}
                      </>
                    )}
                    Run Payroll
                  </h3>
                </div>
                {show === 1 && !showp && (
                  <div className="dropdown dropdown-action col-auto float-end ml-auto">
                    <div className="view-icons">
                      <button
                        className="btn btn-success btn-block "
                        style={{
                          marginTop: "20px",
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                        }}
                        data-bs-toggle="modal"
                        data-bs-target="#PFDeduction"
                      >
                        Calculate PF Deduction{" "}
                      </button>
                    </div>
                  </div>
                )}
                {/* <div className="dropdown dropdown-action col-auto float-end ml-auto">
                  <div className="view-icons">
                    <a
                      href="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <Button variant="contained">
                        <MoreHorizIcon />
                      </Button>
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_employee"
                      >
                        <b> import .csv, xslx</b>
                      </a>
                      <a
                        className="dropdown-item"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_employee"
                      >
                        <b> Upload .csv, xslx</b>
                      </a>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {show === 0 && (
              <div className="row">
                <br />
                <br />
                <br />
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6"></div>
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div
                    className="card dash-widget"
                    style={{
                      height: "110px",
                      backgroundColor: "#3298DB",
                      border: "none",
                    }}
                  >
                    <div
                      className="card-body kpi-pay"
                      onClick={() => setShow(1)}
                    >
                      <h4 style={{ color: "white" }}> Run New Payroll </h4>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                  <div
                    className="card dash-widget"
                    style={{
                      height: "110px",
                      backgroundColor: "#2DB8C5",
                      border: "none",
                    }}
                  >
                    <div className="card-body kpi-pay">
                      <h4
                        style={{ color: "white" }}
                        onClick={() => navigate("/history-payroll")}
                      >
                        Payroll History{" "}
                      </h4>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3"></div>
              </div>
            )}
            {show === 1 && !showp && (
              <Addpayroll
                show={show}
                setShow={setShow}
                empPay={empPay}
                setEmpPay={setEmpPay}
                handleGetPayrollS3={ handleGetPayrollS3}
                gDate={gDate}
               
                month={month}
                setMonth={setMonth}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                pay={pay}
                dyCol={ sessionStorage.getItem(`coid`)===`Ram 067802`?rrwlcolumns:dyCol}
                setEmpi={setEmpi}
                empi={empi}
                showp={showp}
                setShowp={setShowp}
                handleHoldPayroll={handleHoldPayroll}
                handlePayslip={handlePayslip}
                show1={show1}
                loading={loading}
              />
            )}
            {show === 1 && showp && (
              <Grid item lg={9} md={9} sm={12} xs={12}>
                <Box sx={{ width: "95%", m: 1, ml: 1.5 }}>
                  {/* <div
                    className="content container-fluid"
                    style={{
                      marginTop: "23px",
                      padding: "8px",
                      paddingLeft: "18px",
                    }}
                  >
                    <div className="page-header">
                      <h3 className="page-title">Payslip</h3>
                    </div>
                  </div> */}

                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      ml: "auto",
                      mr: "auto",
                      p: 1,
                    }}
                  >
                    <div className="col-md-3">
                      <span>
                        <button
                          className="btn btn-success btn-block "
                          style={{
                            padding: "4px 4px",
                            fontSize: "15px",
                            background: " #3298DB",
                            border: "none",
                            color: "white",
                            borderRadius: "20px",
                          }}
                          classname="edit-icon"
                          onClick={() => setShowp(false)}
                        >
                          <ArrowBack />
                        </button>
                      </span>
                      {/* <input
                        type="month"
                        className="col-md-12"
                        style={{ height: "40px", borderRadius: "4px" }}
                        value={month}
                        onChange={(e) => setMonth(e.target.value)}
                      /> */}
                    </div>

                    <SButton onClick={printPage}>
                      <Typography variant="body2" sx={{ color: "#FFFFFF" }}>
                        Download
                      </Typography>
                    </SButton>
                  </Box>
                  {sessionStorage.getItem("coid") === "Sapi141335" && (
                    <>
                      <Box sx={{ background: "#FFFFFF", borderRadius: "4px" }}>
                        <Box
                          ref={inputRef}
                          sx={{
                            textAlign: "left",
                            p: 1.5,
                            ml: "auto",
                            mr: "auto",
                          }}
                        >
                          <Grid container sx={{ mb: 1 }}>
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  textAlign: "center",
                                  p: 1,
                                }}
                              >
                                <img
                                  src={SapioLogo}
                                  alt=""
                                  style={{
                                    height: "auto",
                                    marginBottom: "8px",
                                    height: "50px",
                                    width: "auto",
                                    // marginLeft: "10px",
                                    marginRight: "auto",
                                  }}
                                />
                              </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  textAlign: "center",
                                  p: 1,
                                }}
                              >
                                <Typography variant="body2">
                                  <b style={{ fontSize: "16px" }}>
                                    {showSlip.user_details?.company_name}
                                  </b>
                                  <br />
                                  <b>
                                    {/* OZ HRMS, Oasis Complex, Ram Ratna House,
                                Pandurang Budhkar Marg, Worli, Mumbai,
                                Maharashtra 400013 */}
                                    {showSlip.user_details?.company_address}
                                  </b>
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  textAlign: "center",
                                  p: 1,
                                }}
                              >
                                <img
                                  src={sessionStorage.getItem("company_logo")}
                                  alt=""
                                  style={{
                                    height: "auto",
                                    marginBottom: "8px",
                                    height: "40px",
                                    width: "auto",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                          <hr style={{ marginTop: "-10px" }} />
                          <Box sx={{ textAlign: "center", p: 1 }}>
                            <Typography variant="body2">
                              <b>
                                <u>
                                  Payslip for the Month of{" "}
                                  {mo[month.split("-")[1]]}-
                                  {month.split("-")[0]}
                                </u>
                              </b>
                            </Typography>
                          </Box>

                          <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                            <TableContainer>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "100%", borderLeftColor: "red" }}
                              >
                                {/* <STableRow sx={{ borderColor: "#FFFFFF" }}>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Employee ID</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {showSlip.user_details?.emp_id}
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>Bank Name:</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.bank_name}
                                </Typography>
                              </SNCTableCell>
                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  <b>PAN</b>
                                </Typography>
                              </SNCTableCell>

                              <SNCTableCell
                                sx={{ width: `${100 / 6}%`, p: 0.75, m: 1 }}
                              >
                                <Typography variant="body2">
                                  {" "}
                                  {showSlip.user_details?.pan}
                                </Typography>
                              </SNCTableCell>
                            </STableRow> */}

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Employee Name</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      : {showSlip.user_details?.emp_name}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Bank Name</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.bank_name}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Days in Month</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.total_days}
                                    </Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Department</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      : {showSlip.user_details?.deptartment}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>A/C</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.acc_no}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Paid Days</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.paid_days}
                                    </Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Designation</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      : {showSlip.user_details?.designation}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>PAN</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.pan}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>LOP Days</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.absent_days}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2"></Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2"></Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                      borderColor: "#FFFFFF",
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Gender</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.gender}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>UAN</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      : {showSlip.user_details?.uan}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                </STableRow>
                              </Table>
                            </TableContainer>
                          </Box>
                          <br />

                          <Box sx={{ height: "100%", p: 1 }}>
                            <TableContainer>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{
                                    width: "50%",
                                    // borderCollapse: "collapse",
                                    border: "1px solid rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <STableRow>
                                    <SCTableCell>
                                      <Typography variant="body2">
                                        <b>Earnings</b>
                                      </Typography>
                                    </SCTableCell>

                                    <SCTableCell align="right">
                                      <Typography variant="body2">
                                        <b>
                                          Total
                                          {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                        </b>
                                      </Typography>
                                    </SCTableCell>
                                  </STableRow>

                                  {showSlip.earning
                                    ?.filter((ele) => ele.total !== 0)
                                    .map((ele) => (
                                      <STableRow>
                                        <TableCell>
                                          <Typography variant="body2">
                                            {ele.component_name}
                                          </Typography>
                                        </TableCell>

                                        <TableCell align="right">
                                          <Typography variant="body2">
                                            {/* {ele.total?.toFixed(0)} */}
                                            {ele?.total?.toLocaleString(
                                              "en-US",
                                              {
                                                style: "currency",
                                                currency: "INR",
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                          </Typography>
                                        </TableCell>
                                      </STableRow>
                                    ))}
                                </Table>
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{
                                    width: "50%",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <STableRow height="20px">
                                    <SCTableCell>
                                      <Typography variant="body2">
                                        <b>Deductions</b>
                                      </Typography>
                                    </SCTableCell>

                                    <SCTableCell align="right">
                                      <Typography variant="body2">
                                        <b>
                                          Total
                                          {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                        </b>
                                      </Typography>
                                    </SCTableCell>
                                  </STableRow>

                                  {showSlip.deduction?.map((ele) => (
                                    <STableRow>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {ele.component_name ===
                                          "otherdeduction"
                                            ? "Other Deduction"
                                            : ele.component_name}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="right">
                                        <Typography variant="body2">
                                          {/* {ele.total?.toFixed(0)} */}
                                          {ele?.total?.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "INR",
                                            minimumFractionDigits: 2,
                                          })}
                                        </Typography>
                                      </TableCell>
                                    </STableRow>
                                  ))}
                                </Table>
                              </Table>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        <b>
                                          Gross Salary
                                          {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                        </b>
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {/* {totalEr?.toFixed(0)} */}
                                        {totalEr?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                </Table>
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        <b>
                                          Total Deductions
                                          {/* (
                                      <img
                                        style={{
                                          width: "10px",
                                          height: "10px",
                                        }}
                                        src={Rupee}
                                      />
                                      ) */}
                                        </b>
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {/* {totalDe?.toFixed(0)} */}
                                        {totalDe?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                </Table>
                              </Table>

                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "100%" }}
                              >
                                {/* <STableRow>
                              <TableCell>
                                <Typography variant="body2">
                                  <b>Reimbursement</b>
                                </Typography>
                              </TableCell>

                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </STableRow> */}

                                {showSlip.reimbursment?.map((ele) => (
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {ele.component_name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2"></Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2"></Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {/* {ele.total?.toFixed(0)} */}
                                        {ele?.total?.toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 2,
                                        })}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                ))}

                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>Gross Earnings</b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell />
                                  <TableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalEr?.toFixed(0)} */}
                                      {totalEr?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>Total Deductions</b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell />
                                  <TableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalDe?.toFixed(0)} */}
                                      {totalDe?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>Total Reimbursements</b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell />
                                  <TableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  />
                                  {totalRe?.toFixed(0)} */}
                                      {totalRe?.toLocaleString("en-US", {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      })}
                                    </Typography>
                                  </TableCell>
                                </STableRow>

                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>Net Salary</b>
                                    </Typography>
                                  </TableCell>

                                  <STableCell />
                                  <STableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {/* <img
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      marginTop: "-2px",
                                    }}
                                    src={Rupee}
                                  /> */}
                                      {showSlip?.total_net?.toLocaleString(
                                        "en-US",
                                        {
                                          style: "currency",
                                          currency: "INR",
                                          minimumFractionDigits: 2,
                                        }
                                      )}
                                      {/* {Math.round(showSlip.total_net)} */}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                                {/* <STableRow>
                              <STableCell>
                                <Typography variant="body2">
                                  <b>Salary in Words</b>
                                </Typography>
                              </STableCell>

                              <STableCell />
                              <STableCell />

                              <STableCell align="right">
                                <Typography variant="body2">
                                  {showSlip.user_details?.in_words} Only
                                </Typography>
                              </STableCell>
                            </STableRow> */}
                              </Table>
                              <div
                                style={{
                                  textAlign: "center",
                                  fontSize: "12px",
                                  fontWeight: "500",
                                }}
                              >
                                <i>
                                  <b style={{ textTransform: "capitalize" }}>
                                    Total Net Payable{" "}
                                    {showSlip?.total_net?.toLocaleString(
                                      "en-US",
                                      {
                                        style: "currency",
                                        currency: "INR",
                                        minimumFractionDigits: 2,
                                      }
                                    )}{" "}
                                    ({getInWords(showSlip.total_net)} Only)
                                  </b>
                                </i>
                                <p>
                                  **Total Net Payable = Gross Earnings - Total
                                  Deductions + Total Reimbursements
                                </p>
                                <br />
                                <i>
                                  <p>
                                    **This is a computer generated Payslip,
                                    doesn't require signature of the competent
                                    Authority**
                                  </p>
                                </i>
                              </div>
                              <br />
                            </TableContainer>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                  {sessionStorage.getItem("coid") !== "Sapi141335" && (
                    <>
                      <Box sx={{ background: "#FFFFFF", borderRadius: "4px" }}>
                        <Box
                          ref={inputRef}
                          sx={{
                            textAlign: "left",
                            p: 1.5,
                            ml: "auto",
                            mr: "auto",
                          }}
                        >
                          <Grid container sx={{ mb: 1 }}>
                            <Grid item lg={3} md={3} sm={3} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  textAlign: "center",
                                  p: 1,
                                }}
                              >
                                <img
                                  src={sessionStorage.getItem("company_logo")}
                                  alt=""
                                  style={{
                                    height: "auto",
                                    marginBottom: "8px",
                                    height: "40px",
                                    width: "auto",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                />
                              </Box>
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignContent: "center",
                                  textAlign: "center",
                                  p: 1,
                                }}
                              >
                                <Typography variant="body2">
                                  <b style={{ fontSize: "16px" }}>
                                    {showSlip.user_details?.company_name}
                                  </b>
                                  <br />
                                  <b>
                                    {showSlip.user_details?.company_address}
                                  </b>
                                  <p>
                                    CIN : {showSlip.user_details?.company_cin}
                                  </p>
                                </Typography>
                              </Box>
                            </Grid>

                            <Grid item lg={3} md={3} sm={3} xs={3} />
                          </Grid>

                          <Box sx={{ textAlign: "center", p: 1 }}>
                            <Typography variant="body2">
                              <b>
                                <u>
                                  PAYSLIP FOR THE MONTH OF{" "}
                                  {mo[month.split("-")[1]]}-
                                  {month.split("-")[0]}
                                </u>
                              </b>
                            </Typography>
                          </Box>

                          <Box sx={{ width: "100%", p: 1, pt: 0 }}>
                            <TableContainer>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "100%", borderLeftColor: "red" }}
                              >
                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Employee ID</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {showSlip.user_details?.emp_id}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Bank Name:</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.bank_name}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>PAN</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.pan}
                                    </Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Employee Name</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {showSlip.user_details?.emp_name}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>A/C</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.acc_no}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>LOP days</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.absent_days}
                                    </Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Department</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {showSlip.user_details?.deptartment}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>UAN</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {showSlip.user_details?.uan}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>ESI</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.esi}
                                    </Typography>
                                  </SNCTableCell>
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Designation</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {showSlip.user_details?.designation}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Days in month</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.total_days}
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                </STableRow>

                                <STableRow sx={{ borderColor: "#FFFFFF" }}>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                      borderColor: "#FFFFFF",
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Gender</b>
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.gender}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      <b>Paid days</b>
                                    </Typography>
                                  </SNCTableCell>

                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  >
                                    <Typography variant="body2">
                                      {" "}
                                      {showSlip.user_details?.paid_days}
                                    </Typography>
                                  </SNCTableCell>
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                  <SNCTableCell
                                    sx={{
                                      width: `${100 / 6}%`,
                                      p: 0.75,
                                      m: 1,
                                    }}
                                  />
                                </STableRow>
                              </Table>
                            </TableContainer>
                          </Box>
                          <br />

                          <Box sx={{ height: "100%", p: 1 }}>
                            <TableContainer>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow>
                                    <SCTableCell>
                                      <Typography variant="body2">
                                        <b>Particular</b>
                                      </Typography>
                                    </SCTableCell>

                                    <SCTableCell align="right">
                                      <Typography variant="body2">
                                        <b>
                                          Total (
                                          <img
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                            src={Rupee}
                                          />
                                          )
                                        </b>
                                      </Typography>
                                    </SCTableCell>
                                  </STableRow>

                                  {showSlip.earning?.map((ele) => (
                                    <STableRow>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {ele.component_name}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="right">
                                        <Typography variant="body2">
                                          {ele.total.toFixed(0)}
                                        </Typography>
                                      </TableCell>
                                    </STableRow>
                                  ))}
                                </Table>
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow height="20px">
                                    <SCTableCell>
                                      <Typography variant="body2">
                                        <b>Deduction</b>
                                      </Typography>
                                    </SCTableCell>

                                    <SCTableCell align="right">
                                      <Typography variant="body2">
                                        <b>
                                          Total (
                                          <img
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                            src={Rupee}
                                          />
                                          )
                                        </b>
                                      </Typography>
                                    </SCTableCell>
                                  </STableRow>

                                  {showSlip.deduction?.map((ele) => (
                                    <STableRow>
                                      <TableCell>
                                        <Typography variant="body2">
                                          {ele.component_name}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="right">
                                        <Typography variant="body2">
                                          {ele.total.toFixed(0)}
                                        </Typography>
                                      </TableCell>
                                    </STableRow>
                                  ))}
                                </Table>
                              </Table>
                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "row",
                                }}
                              >
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        <b>
                                          Gross Salary (
                                          <img
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                            src={Rupee}
                                          />
                                          )
                                        </b>
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {totalEr}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                </Table>
                                <Table
                                  size="small"
                                  aria-label="a dense table"
                                  sx={{ width: "50%" }}
                                >
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        <b>
                                          Total Deductions (
                                          <img
                                            style={{
                                              width: "10px",
                                              height: "10px",
                                            }}
                                            src={Rupee}
                                          />
                                          )
                                        </b>
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {totalDe.toFixed(0)}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                </Table>
                              </Table>

                              <Table
                                size="small"
                                aria-label="a dense table"
                                sx={{ width: "100%" }}
                              >
                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>Reimbursment</b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell />
                                  <TableCell />
                                  <TableCell />
                                </STableRow>

                                {showSlip.reimbursment?.map((ele) => (
                                  <STableRow>
                                    <TableCell>
                                      <Typography variant="body2">
                                        {ele.component_name}
                                      </Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2"></Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2"></Typography>
                                    </TableCell>

                                    <TableCell align="right">
                                      <Typography variant="body2">
                                        {ele.total.toFixed(0)}
                                      </Typography>
                                    </TableCell>
                                  </STableRow>
                                ))}

                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>
                                        Total Reimbursment (
                                        <img
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                          }}
                                          src={Rupee}
                                        />
                                        )
                                      </b>
                                    </Typography>
                                  </TableCell>

                                  <TableCell />
                                  <TableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {totalRe}
                                    </Typography>
                                  </TableCell>
                                </STableRow>

                                <STableRow>
                                  <TableCell>
                                    <Typography variant="body2">
                                      <b>
                                        Net Salary (
                                        <img
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                          }}
                                          src={Rupee}
                                        />
                                        )
                                      </b>
                                    </Typography>
                                  </TableCell>

                                  <STableCell />
                                  <STableCell />

                                  <TableCell align="right">
                                    <Typography variant="body2">
                                      {Math.round(showSlip.total_net)}
                                    </Typography>
                                  </TableCell>
                                </STableRow>
                                {/* <STableRow>
                              <STableCell>
                                <Typography variant="body2">
                                  <b>Salary in Words</b>
                                </Typography>
                              </STableCell>

                              <STableCell />
                              <STableCell />

                              <STableCell align="right">
                                <Typography variant="body2">
                                  {showSlip.user_details?.in_words}
                                </Typography>
                              </STableCell>
                            </STableRow> */}
                              </Table>
                              <div style={{ textAlign: "center" }}>
                                <i>
                                  <b>
                                    Total Net Payable ₹
                                    {Math.round(showSlip.total_net)} (
                                    {showSlip.user_details?.in_words} Only)
                                  </b>
                                </i>
                                <p>
                                  **Total Net Payable = Gross Earnings - Total
                                  Deductions + Total Reimbursements
                                </p>
                                <br />
                                <i>
                                  <p>
                                    **This is a computer generated Payslip,
                                    doesn't require signature of the competent
                                    Authority**
                                  </p>
                                </i>
                              </div>
                              <br />
                            </TableContainer>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              </Grid>
            )}
            {show === 2 && <ViewPayslip />}
            {show === 3 && <RevisePayroll />}
            {/* {show === 4 && <PayrollHistory />} */}
          </div>
          {show === 1 && (
            <div className="col-md-3 right-bar">
              <h5 className="page-title1">PAYROLL MONTH</h5>
              <div className=" col-md-10">
                <div className="form-group">
                  <input
                    type="month"
                    className="form-control input-height"
                    placeholder="Month"
                    value={month}
                    style={{
                      height: "50px",
                      width: "100%",
                      border: "1px solid lightgrey",
                      borderRadius: "3px",
                    }}
                    onChange={(e) => setMonth(e.target.value)}
                  />
                  {/* <label className="focus-label">Location</label> */}
                </div>
              </div>
              <button   className="btn btn-success btn-block "
                        style={{
                          marginTop: "20px",
                          padding: "8px 23px",
                          fontSize: "15px",
                          background: "#3298db",
                          marginLeft:`20px`,
                          border: "none",
                          color: "white",
                          borderRadius: "10px",
                        }} onClick={()=>handleGetPayroll1()}>Regenerate Payroll</button>
              <br />
              <br />
              <h5 className="page-title1">NO. OF EMPLOYEES</h5>
              <h5 className="page-title">
                <b>{selectedRowKeys.length}</b>
              </h5>
              <br />
              {/* <h5 className="page-title1">PAYMENT AMOUNT</h5>
              <h5 className="page-title">
                <b>
                  {" "}
                  <img
                    src={rupee}
                    style={{ height: "24px", width: "24px", marginTop: "-5px" }}
                  />{" "}
                  {Math.round(sum)}
                </b>
              </h5> */}
              {rolePermissions["Run Payroll"] === "Edit/Create/Approve" && (
                <>
                  <div className="payroll-btn-div">
                    <button
                      // href="#"

                      disabled={selectedRowKeys.length === 0 ? true : false}
                      className={
                        selectedRowKeys.length === 0
                          ? "btn-payroll-d"
                          : "btn-payroll"
                      }
                      // data-bs-toggle="modal"
                      // data-bs-target="#accept"
                      onClick={openModal}
                    >
                      Execute Payroll
                    </button>
                  </div>
                  <br />
                </>
              )}
              {rolePermissions["Export Run Payroll Report"] === "View" && (
                <>
                  {/*<div className="payroll-btn-div">
                    {show1?.length !== 0 && (
                      <CSVLink
                        data={execute}
                        headers={dyCol.map((ele) => ({
                          label: ele.name.replace(".", ""),
                          key: ele.key,
                        }))}
                      >
                        Export to CSV
                      </CSVLink>
                    )}
                  </div>*/}
                </>
              )}
            </div>
          )}
        </div>

        {/* /Page Content */}
      </div>
      {/* PF Deduction calculation */}
      <div
        className="modal custom-modal fade"
        role="dialog"
        id="PFDeduction"
        // tabindex="-1"
        // aria-labelledby="exampleModalLabel"
        // aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                style={{ marginTop: "10px", fontWeight: "600" }}
              >
                Monthly PF Deduction
              </h5>
              <button
                type="button"
                className="close-btn"
                data-bs-dismiss="modal"
              >
                <img src={closeIcon} />
              </button>
            </div>
            <div class="modal-body">
              <form>
                <div class="form-group">
                  <label for="message-text" class="col-form-label">
                    <b>Select Month :</b>
                  </label>
                  <input
                    class="form-control"
                    id="message-text"
                    min="2024-05"
                    type="month"
                    value={monthValue}
                    onChange={(e) => setMonthValue(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                onClick={(e) => handlePFDeduction(e)}
                data-bs-dismiss="modal"
                className="submit-btn2"
              >
                Calculate
              </button>
            </div>
          </div>
        </div>
      </div>

      <div id="accept" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
            </div>
            <div className="modal-body">
              <button data-bs-dismiss="modal">Close</button>
              <button onClick={() => setShowDown(true)}>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <ModalTransition>
        {isOpen && (
          <Modal onClose={closeModal}>
            {/* <CustomHeader /> */}
            <ModalBody>
              <br />
              <br />
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <input type="checkbox" className="check-item" />
                &nbsp; &nbsp;
                <p>
                  Confirm if all the newly added employees are added into the
                  payroll.
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <input type="checkbox" className="check-item" />
                &nbsp; &nbsp;
                <p>Confirm if all the overtime/loss of pay are verified.</p>
              </div>
              {/* <div style={{ display: "flex", alignItems: "flex-start" }}>
                <input type="checkbox" className="check-item" />
                &nbsp; &nbsp;
                <p>
                  Modal footer accepts any valid React element as children. For
                  example, you can add an avatar in the footer. For very custom
                  use cases, you can achieve the same thing without modal
                  footer.
                </p>
              </div> */}
            </ModalBody>
            <ModalFooter>
              <Button
                appearance="primary"
                onClick={() => {
                  handleExecute();
                }}
              >
                Submit
              </Button>
              <Button appearance="subtle" onClick={closeModal} autoFocus>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </ModalTransition>
    </div>
  );
};
export default Runpayroll;
